<template>
  <div class="container">
    <div class="row" v-if="Object.keys(timeList).length > 0">
      <div class="col-12">
        <h3 class="text-center mb-3 mt-4">Eingetragen</h3>
      </div>
    </div>
    <div class="day card"  v-for="(times, day) in timeList" :key="day">
      <div class="day-header">
        <div class="time-header row dayheaderTop">
          <div class="col-6">
            <p class="mb-2"><i class="fa-duotone fa-calendar-days"></i> {{ $filters.formatDate(day) }}</p>
          </div>
          <div class="col-6 text-start"><i class="fa-duotone fa-equals"></i> {{ times.sum }} h
          </div>
        </div>
        <div class="day-content">
          <div class="time" v-for="time in times.days" :key="time.id">
            <div class="time-header row">
              <div class="col-6">
                <p class="mb-1"><i class="fa-duotone fa-clock-two"></i> {{ $filters.formatTimeToGerman(time.from) }} -
                  {{ $filters.formatTimeToGerman(time.till) }}</p>
              </div>
              <div class="col-4 text-start">
                <template v-if="times.days.length > 1"><i class="fa-duotone fa-equals"></i> {{ time.sum }} h</template>
              </div>
              <div class="col-2 text-center">
                <div class="dropdown">
                  <button class="btn btn-transparent dropdown-toggle" type="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                          @click="toggleMenu(time.id)">
                    <i class="fa-duotone fa-ellipsis-vertical"></i>
                  </button>
                  <div class="dropdown-menu" :class="(showMenu === time.id) ? 'show' : ''"
                      >
                    <button class="btn-close float-end" @click="showMenu = null;"></button>
                    <button @click="editTime(time)" class="dropdown-item" ><i class="fa-duotone fa-pen"></i> Bearbeiten</button>
                    <button @click="deleteTime(time.id)" class="dropdown-item" ><i class="fa-duotone fa-trash" style="margin-right: 3px;"></i> Löschen</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {useToast} from 'vue-toastification';
import {mapGetters} from "vuex";

export default {
  name: 'TimeList',
  emits: ['time-deleted', 'edit-time'],
  data() {
    return {
      showMenu: null
    }
  },
  computed: {
    ...mapGetters(['getApiUrl']),
  },
  props: {
    timeList: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleMenu(id) {
      if(this.showMenu === id) {
        this.showMenu = null;
      } else {
        this.showMenu = id;
      }
    },
    deleteTime(id){
      const toast = useToast();
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.getApiUrl +'time/' + id, {headers} )
        .then(() => {
          toast.success('Zeit wurde gelöscht');
          this.$emit('time-deleted');
          this.showMenu = null;
        })
        .catch(() => {
          toast.error('Zeit konnte nicht gelöscht werden');
        })
    },
    editTime(time) {
      this.$emit('edit-time', time);
      this.showMenu = null;
    }
  }
}
</script>

