<template>
  <div class="header headerBigImage">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="logoText"><img src="/logo.svg" class="logoImg"> sketch.<strong>tracker</strong></div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="container mt-5">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="fa-duotone fa-envelope"></i>
                      </div>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="E-Mail"
                        v-model="c.username"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="fa-duotone fa-lock"></i>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        id="password"
                        placeholder="Passwort"
                        v-model="c.password"
                      />
                    </div>
                  </div>
                  <button
                    href="#"
                    class="btn btn-outline-primary text-uppercase w-100 mt-3"
                    @keyup.enter="login(c)"
                    @click="login(c)"
                  >
                    Anmelden
                  </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'UserLogin',
  watch: {},
  data() {
    return {
      c: {
        username: '',
        password: '',
      }
    }
  },
  methods: {
    ...mapActions(["login"]),
    getUser() {
    }
  }
}
</script>
<style scoped>

</style>
