<template>
  <div class="wrapperTime">
    <div class="dashboard2" v-if="step === 'time'">
      <MainHeader headline="Zeit eintragen" :employee="employee" :icon="'fa-calendar-pen'" text=""/>
      <div class="container mt-3 mb-3 inputTimeWrapper" v-if="showInsert">
        <div class="w-100 text-end">
          <button class="btn-close mb-2" @click="showInsert = false; " type="button"></button>
        </div>
        <div class="input-group mb-2">
          <Datepicker class="form-control" ref="pickDate" v-model="time.date" auto-apply model-type="yyyy-MM-dd"
                      format="dd.MM.yyyy" locale="de"
                      :enable-time-picker="false" cancel-text="X" select-text="Wählen">
            <template #input-icon>
              <i class="fa-duotone fa-calendar-days"></i>
            </template>
          </Datepicker>
          <div class="input-group-append">
            <div class="btn btn-primary" @click="$refs.pickDate.openMenu()"><i class="fa-duotone fa-pencil-alt"></i>
            </div>
          </div>
        </div>
        <div class="input-group mb-2">
          <Datepicker class="form-control" ref="timeStart" v-model="time.start" time-picker auto-apply>
            <template #input-icon>
              <i class="fa-duotone fa-hourglass-start"></i>
            </template>
          </Datepicker>
          <div class="input-group-append">
            <div class="btn btn-primary" @click="$refs.timeStart.openMenu()"><i class="fa-duotone fa-pencil-alt"></i>
            </div>
          </div>
        </div>
        <div class="input-group mb-2">
          <Datepicker v-model="time.end" ref="timeEnd" time-picker auto-apply class="form-control">
            <template #input-icon>
              <i class="fa-duotone fa-hourglass-start"></i>
            </template>
          </Datepicker>
          <div class="input-group-append">
            <div class="btn btn-primary" @click="$refs.timeEnd.openMenu()"><i class="fa-duotone fa-pencil-alt"></i>
            </div>
          </div>
        </div>
        <div class="input-group mb-2">
          <select  style="width: 100%"  v-model="time.working_type " >
            <option value="" selected="selected">-Arbeitsart-</option>
            <option value="normal">Arbeitszeit</option>
            <option value="krank">Krank</option>
            <option value="urlaub">Urlaub</option>
            <option value="sonderurlaub">Sonderurlaub</option>
          </select>
        </div>
        <div class="input-group mb-2">
          <select style="width: 100%" v-model="time.activity_id" name="" id="">
            <option v-for="activity in activities" :value="activity.id">{{ activity.title }}</option>
          </select>
        </div>
        <div class="input-group mb-2">
          <textarea placeholder="Kommentar" v-model="time.reason" name="" id="" style="width: 100%; max-width: 100%; min-height: 50px"></textarea>
        </div>
        <div class="input-group"><button class="btn btn-outline-primary" @click="sendTime()"><i class="fa-duotone fa-floppy-disk"></i> Speichern</button></div>
      </div>
      <TimeList :timeList="timeList" v-if="!showInsert" @time-deleted="getTimes" @edit-time="setEditTime"/>
    </div>
    <div class="dashboard2" v-if="step === 'plan'">
      <MainHeader headline="Dienstplan" :employee="employee" text="" icon="fa-suitcase"/>
      <div class="container pt-4"><p style="text-align: center">Comming soon</p></div>
      <div class="container pt-4" style="display: none">
        <div class="row">
          <div class="col-lg-12 mb-3" v-for="(times, weekDay) in customFields.times"
               :key="weekDay">
            <div class="card">
              <div class="card-body p-2">
                <div class="workDay">
                  <div class="row">
                    <div class="col"><strong>{{ $t('weekday.' + weekDay) }}</strong></div>
                    <div class="col">
                    </div>
                  </div>
                  <div class="times">
                    <div class="row">
                      <div class="col" style="border-right: 2px solid #000;">
                        <div class="time" v-for="time in times.items">
                          {{ time.from }} - {{ time.till }}
                        </div>
                      </div>
                      <div class="col">
                        <div style="text-align: center"><small>Gesamt</small><br/><strong class="fs-5">{{ times.sum }}</strong></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard2" v-if="step === 'checkin'">
      <MainHeader headline="Timer" :employee="employee" :icon="'fa-stopwatch'" text=""/>
      <div class="container pt-3">
        <div class="row">
          <div class="col-lg-12 d-flex align-items-stretch mb-4">
            <div class="card w-100 flex-fill">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-12 col-lg-12 text-center checkinCountdown" :class="checkinState.type">
                    <template v-if="checkinState.type !== 'paused' && checkinState.type !== 'offline'">
                      <Timer :set-time="checkinState.checkin" v-if="checkinState.checkin"/>
                    </template>
                    {{ $t('checkin.types.' + checkinState.type) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard2 infos" v-if="step === 'info'">
      <MainHeader headline="Information" :employee="employee" :icon="'fa-info'" text=""/>
      <div class="container"><p style="text-align: center; padding:48px;">Comming soon!</p></div>
      <div class="container" style="display: none">
        <div class="card overtime text-center mt-4 mb-4 " v-if="employee.timeStory">
          <h6 class="card-heading"><i class="fa-duotone fa-clock-rotate-left"></i> Übersicht</h6>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-4">Ist<br>
                <strong>{{ employee.timeStory.credit }}</strong>
              </div>
              <div class="col-4">Soll<br>
                <strong>{{ employee.timeStory.debit }}</strong>
              </div>
              <div class="col-4">Differenz<br>
                <strong>{{ employee.timeStory.difference }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="card overtime text-center mt-4 mb-4 " v-if="overtime">
          <h4 class="card-heading line"><span><i class="fa-duotone fa-clock-rotate-left"></i> Überstunden</span></h4>
          <div class="card-body p-2">
            <div class="row">
              <div class="col-6">Vormonat<br>
                <strong>{{ overtime.lastMonth }}</strong>
              </div>
              <div class="col-6">Aktuell<br>
                <strong>{{ overtime.current }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="card vacation text-center">
          <h6 class="card-heading line"><span><i class="fa-duotone fa-suitcase"></i> Urlaubstage</span></h6>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-6">Gesamt<br>
                <strong>{{ vacation.total }}</strong>
              </div>
              <div class="col-6">Verbleibend<br>
                <strong>{{ vacation.remaining }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixedMenu">
      <ul>
        <li :class="(step === 'checkin') ? 'active' : ''"><span @click="step = 'checkin'"><i
          class="fa-duotone fa-stopwatch"></i><br/>Timer</span></li>
        <li :class="(step === 'time') ? 'active' : ''"><span @click="step = 'time'"><i
          class="fa-duotone fa-calendar-pen"></i><br/>Zeiten</span></li>
        <li class="specialButton">
          <template v-if="step ==='checkin'">
            <template v-if="checkinState.type !== 'online'">
              <div class="wrapButton">
                <button class="btn btn-round" @click="checkin('online')">
                  <i class="fa-duotone fa-play"></i></button>
              </div>
            </template>
            <template v-if="checkinState.type !== 'paused' && checkinState.type !== 'offline'">
              <div class="wrapButton stop">
                <button class="btn btn-round " @click="checkin('offline')">
                  <i class="fa-duotone fa-stop"></i></button>
              </div>
            </template>
          </template>
          <template v-if="step==='time'">
            <template v-if="!showInsert">
              <div class="wrapButton new">
                <button class="btn btn-round " @click="showInsert = true">
                  <i class="fa-duotone fa-plus"></i></button>
              </div>
            </template>
            <template v-if="showInsert">
              <div class="wrapButton send">
                <button class="btn btn-round" @click="sendTime()">
                  <i class="fa-duotone fa-floppy-disk"></i>
                </button>
              </div>
            </template>
          </template>
          <template v-if="step==='plan'">
            <div class="wrapButton neutral">
              <button class="btn btn-round">
                <i class="fa-duotone fa-business-time"></i>
              </button>
            </div>
          </template>
          <template v-if="step==='info'">
            <div class="wrapButton neutral">
              <button class="btn btn-round">
                <i class="fa-duotone fa-info"></i>
              </button>
            </div>
          </template>
        </li>
        <li :class="(step === 'plan') ? 'active' : ''"><span @click="step = 'plan'"><i
          class="fa-duotone fa-business-time"></i><br/>Dienstplan</span></li>
        <li :class="(step === 'info') ? 'active' : ''"><span @click="step = 'info'"><i
          class="fa-duotone fa-info"></i><br/>Info</span></li>
      </ul>
    </div>
  </div>
</template>
<script>
import MainHeader from "@/components/MainHeader";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {useToast} from 'vue-toastification';
import TimeList from "@/views/subs/TimeList";
import Timer from "@/views/subs/Timer";

export default {
  name: 'DashboardView',
  components: {MainHeader, TimeList, Timer},
  computed: {
    ...mapGetters(['getApiUrl']),
  },
  data() {
    return {
      time: {
        working_type: "normal",
        activity_id: "1",
        date: '',
        start: '',
        end: '',
        reason: '',
      },
      overtime: {
        lastMonth: 8.45,
        current: 0.75,
      },
      vacation: {
        total: 24,
        remaining: 10,
      },
      customFields:
        {
          "timesGlobal": {"daily": "7.7"},
          "times": {
            "1": {"day": "1", items:[{"from": "08:00", "till": "12:00"}, {"from": "13:00", "till": "17:00"}], sum: "8"},
            "2": {"day": "2", items:[{"from": "08:30", "till": "16:30"}], sum: "8"},
            "3": {"day": "3", items:[{"from": "08:30", "till": "16:30"}], sum: "8"},
            "4": {"day": "4", items:[{"from": "08:30", "till": "16:30"}], sum: "8"},
            "5": {"day": "5", items:[{"from": "08:30", "till": "15:00"}], sum: "6.5"}
          }
        }
      ,
      showInsert: false,
      alreadySentToday: false,
      projects: [],
      tasks: [],
      users: [],
      activities: [],
      timeList: [],
      todayString: '',
      employee: {},
      step: 'checkin',
      checkinState: 'checkedOut',
    }}
  ,
  created() {
    this.time.start = {hours: 8, minutes: 0};
    this.time.end = {hours: 16, minutes: 0};
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    this.todayString = yyyy + '-' + mm + '-' + dd;
    this.time.date = this.todayString;
    this.getEmployee();
    this.getActivities();
    this.getTimes();
    this.getCheckinState();
  },
  methods: {
    ...mapActions(["logout"]),
    getActivities() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + "config/issue/activities", {headers}).then((response) => {
        this.activities = response.data.result.data;

      }).catch((error) => {
        console.log(error);
      });
    },

    getTimes() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + "time/list", {headers}).then((response) => {
        this.timeList = response.data.result.data.returner;
        // get today Y-m-d
        this.alreadySentToday = !!(this.timeList[this.todayString]);
      }).catch((error) => {
        console.log(error);
      });
    },
    triggerClick(ref) {
      this.$refs[ref].activate();
    },
    getCheckinState() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + "time/checkinState", {headers}).then((response) => {
        this.checkinState = response.data.result.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    getEmployee() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.getApiUrl + "employee/" + this.$store.state.user._isEmployee, {headers}).then((response) => {
        this.employee = response.data.result;
      }).catch((error) => {
        console.log(error);
      });
    },
    sendTime() {
      this.alreadySentToday = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const toast = useToast();
      if (this.time && this.time.id) {
        axios.put(this.getApiUrl + 'time/' + this.time.id, this.time, {headers})
          .then(response => {
            this.timeList = response.data.result.data.returner;
            toast.success("Zeit erfolgreich geändert");
            this.showInsert = false;
          })
          .catch(error => {
            this.$store.dispatch("handleApiError", error);
          });
      } else {
        axios.post(this.getApiUrl + 'time', this.time, {headers})
          .then(response => {
            this.timeList = response.data.result.data.returner;
            toast.success("Zeit erfolgreich eingetragen");
            this.showInsert = false;
          })
          .catch(error => {
            this.$store.dispatch("handleApiError", error);
          });
      }
    },
    setEditTime(time) {
      this.time.start.hours = this.getHour(time.from);
      this.time.start.minutes = this.getMinutes(time.from)
      this.time.id = time.id;
      this.time.end.hours = this.getHour(time.till);
      this.time.end.minutes = this.getMinutes(time.till);
      this.time.date = time.day;
      this.showInsert = true;
    },
    getHour(time) {
      return time.split(':')[0];
    },
    getMinutes(time) {
      return time.split(':')[1];
    },
    checkin($type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const toast = useToast();
      axios.post(this.getApiUrl + 'time/checkin', {type: $type}, {headers})
        .then(response => {
          if (response.data.result.data) {
            this.checkinState = response.data.result.data;
            this.getTimes();
            toast.success(this.$t('checkin.types.' + $type));
          } else {
            toast.error("Aktion war nicht erfolgreich");
          }
        })
        .catch(error => {
          this.$store.dispatch("handleApiError", error)
        });
    }
  }
}
</script>
<style lang="scss">
.dp__pointer {
  text-indent: 24px;
  line-height: 24px;
}

.dp__cell_inner {
  text-indent: 0;
}

.day-header h3 {
  font-size: 14px;
}

.mainHeader h1 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 36px;
}

.mainHeader h2 {
  font-weight: normal;
  font-size: 20px;
}

footer a {
  color: #fff !important;
}

.headerBigImage {
  .container {
    position: relative;
    z-index: 301;
  }
}

.day-content {
  font-size: 14px;
}

h3 {
}

.day-content h4 {
  font-size: 16px;
  font-weight: normal;
}

.input-group {
  .dp__main.dp__theme_light.form-control {
    padding: 0;
  }
  .input-group-append {
    .btn.btn-primary {
      height: 100%;
      display: inline-flex;
      align-items: center;
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }
}
.inputTimeWrapper{width: 500px; margin-left: auto; margin-right: auto;}
.dashboard2 {
  min-height: 83vh;
}
</style>
